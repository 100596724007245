import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  fetchEthnicities,
  fetchGenders,
  fetchRaces,
  fetchRelationships,
  postGuardian,
  postMember,
} from "../../../services/httpClient";
import DatePicker from "../../ui/DatePicker";
import Input from "../../ui/Input";
import InputPhone from "../../ui/InputPhone";
import SelectObject from "../../ui/SelectObject";
import Select from "../../ui/Select";
import HouseholdCondensedCard from "./HouseholdCondensedCard";
import { User } from "../../../types/user";

interface CreateMemberState {
  fetchHousehold: any;
  startingGuardians: any;
  startingHousehold: any;
  startingChildren: any;
  onClickDoneEdit: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const CreateMember = ({
  fetchHousehold,
  startingGuardians,
  startingHousehold,
  startingChildren,
  onClickDoneEdit,
  setShowToast,
  setToastBody,
  setToastText,
}: CreateMemberState) => {
  const { id } = useParams();
  const [guardians, setGuardians] = useState<User[]>(startingGuardians);
  const [household, setHousehold] = useState<User[]>(startingHousehold);
  const [children, setChildren] = useState<User[]>(startingChildren);
  const heading = "Create Household Member";
  const [type, setType] = useState<string>("");
  const [firstName, setFirstName] = useState<string | undefined>("");
  const [lastName, setLastName] = useState<string>("");
  const [preferredName, setPreferredName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [relationship, setRelationship] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>();
  const [school, setSchool] = useState<string>("");
  const [grade, setGrade] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [race, setRace] = useState<string>("");
  const [ethnicity, setEthnicity] = useState<string>("");

  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [errMsg, setErrMsg] = useState("");

  const [relationships, setRelationships] = useState<any[]>([]);
  const [allRelationships, setAllRelationships] = useState<any[]>([]);
  const [genders, setGenders] = useState<any[]>([]);
  const [races, setRaces] = useState<any[]>([]);
  const [ethnicities, setEthnicities] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  var types: string[] = ["Guardian", "Adult", "Child"];

  useEffect(() => {
    getRelationships();
    getGenders();
    getRaces();
    getEthnicities();
  }, []);

  const getRelationships = () => {
    fetchRelationships().then((res: any) => {
      setAllRelationships(res[0].data);
      setRelationships(
        res[0].data.filter((c: any) => {
          return c.key !== "child";
        })
      );
    });
  };

  const getGenders = () => {
    fetchGenders().then((res: any) => {
      setGenders(res[0].data);
    });
  };

  const getRaces = () => {
    fetchRaces().then((res: any) => {
      setRaces(res[0].data);
    });
  };

  const getEthnicities = () => {
    fetchEthnicities().then((res: any) => {
      setEthnicities(res[0].data);
    });
  };

  function validateEmail(email: string) {
    var regexp = /\S+@\S+\.\S+/;
    return regexp.test(email);
  }

  function validatePhone(phone: string) {
    let length = phone.length;
    return length === 11;
  }

  function enableSave() {
    console.log(phone);
    if (type === "Guardian") {
      if (email && phone && isValidEmail && isValidPhone) {
        setSaveIsEnabled(true);
      } else {
        setSaveIsEnabled(false);
      }
    } else {
      if (phone && isValidPhone) {
        setSaveIsEnabled(true);
      } else if (!phone) {
        setSaveIsEnabled(true);
      } else {
        setSaveIsEnabled(false);
      }
    }
  }

  const saveMember = (isDone: boolean) => {
    setErrMsg("");

    if (type === "Guardian") {
      if (isValidEmail && isValidPhone) {
        const updatedGuardian: User = {
          role: "family",
          firstname: firstName,
          lastname: lastName,
          preferredname: preferredName,
          email: email,
          phonenumber: phone?.toString(),
          relationship: relationship,
          birthdate: birthDate,
          gender: gender,
          race: race,
          ethnicity: ethnicity,
          is_guardian: true,
          is_new: true,
        };

        setIsSaving(true);
        postGuardian(updatedGuardian, id)
          .then((res: any) => {
            setSaveIsEnabled(false);
            setGuardians((prev) => [...prev, updatedGuardian]);
            clearMember();
            setShowToast(true);
            setToastText("Guardian Created");
            const toast = preferredName
              ? firstName + " '" + preferredName + "' " + lastName
              : firstName + " " + lastName;
            setToastBody(toast);
          })
          .catch((err: any) => {
            setErrMsg(err?.response?.data?.msg);
            setIsSaving(false);
          })
          .finally(() => {
            if (isDone) {
              fetchHousehold();
              onClickDoneEdit();
            }
            setIsSaving(false);
          });
      } else {
        setErrMsg("Email or Phone is in Invalid Format");
      }
    } else if (type === "Adult" || type === "Child") {
      if (isValidPhone) {
        const updatedMember: User = {
          role: "",
          firstname: firstName,
          lastname: lastName,
          preferredname: preferredName,
          phonenumber: phone?.toString(),
          relationship: relationship,
          birthdate: birthDate,
          gender: gender,
          race: race,
          ethnicity: ethnicity,
          school: school,
          grade: grade,
          is_new: true,
        };

        setIsSaving(true);
        postMember(updatedMember, id)
          .then((res: any) => {
            setSaveIsEnabled(false);
            if (type === "Adult") {
              setHousehold((prev) => [...prev, updatedMember]);
            } else {
              setChildren((prev) => [...prev, updatedMember]);
            }
            clearMember();
            setShowToast(true);
            setToastText("Member Created");
            const toast = preferredName
              ? firstName + " '" + preferredName + "' " + lastName
              : firstName + " " + lastName;
            setToastBody(toast);
          })
          .catch((err: any) => {
            setErrMsg(err?.response?.data?.msg);
            setIsSaving(false);
          })
          .finally(() => {
            if (isDone) {
              fetchHousehold();
              onClickDoneEdit();
            }
            setIsSaving(false);
          });
      } else {
        setErrMsg("Phone is in Invalid Format");
      }
    }
  };

  const clearMember = () => {
    setType("");
    setFirstName("");
    setLastName("");
    setPreferredName("");
    setEmail("");
    setPhone(null);
    setRelationship("");
    setBirthDate("");
    setSchool("");
    setGrade("");
    setGender("");
    setRace("");
    setEthnicity("");
  };

  const updateType = (e: any) => {
    setType(e.target.value);
    if (e.target.value === "Child") {
      setRelationship("child");
    }
  };

  const updateFirstName = (e: any) => {
    setFirstName(e.target.value);
    enableSave();
  };

  const updateLastName = (e: any) => {
    setLastName(e.target.value);
    enableSave();
  };

  const updateEmail = (e: any) => {
    setEmail(e.target.value);

    let validEmail = validateEmail(e.target.value);
    if (validEmail) {
      setIsValidEmail(true);
      if (isValidPhone) {
        if (phone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      }
    } else {
      setIsValidEmail(false);
      setSaveIsEnabled(false);
    }
  };

  const updatePhone = (e: any) => {
    setPhone(e);
    let validPhone = validatePhone(e);
    if (validPhone) {
      setIsValidPhone(true);
      if (type === "Guardian") {
        if (isValidEmail) {
          if (email) {
            setSaveIsEnabled(true);
          } else {
            setSaveIsEnabled(false);
          }
        }
      } else {
        setSaveIsEnabled(true);
      }
    } else {
      setIsValidPhone(false);
      setSaveIsEnabled(false);
    }
  };

  const updatePreferredName = (e: any) => {
    setPreferredName(e.target.value);
    enableSave();
  };

  const updateRelationship = (e: any) => {
    setRelationship(e.target.value);
    enableSave();
  };

  const updateBirthDate = (e: any) => {
    setBirthDate(e);
    enableSave();
  };

  const updateSchool = (e: any) => {
    setSchool(e.target.value);
    enableSave();
  };

  const updateGrade = (e: any) => {
    setGrade(e.target.value);
    enableSave();
  };

  const updateGender = (e: any) => {
    setGender(e.target.value);
    enableSave();
  };

  const updateRace = (e: any) => {
    setRace(e.target.value);
    enableSave();
  };

  const updateEthnicity = (e: any) => {
    setEthnicity(e.target.value);
    enableSave();
  };

  const onClickDoneCreate = () => {
    fetchHousehold();
    onClickDoneEdit();
  };

  return (
    <>
      <div>
        <Stack gap={3}>
          <Row>
            <Col>
              <h4 className="app-header-text">{heading}</h4>
            </Col>
            <Col className="text-end">
              <Stack className="stack-float" direction="horizontal" gap={3}>
                <Button className="app-primary-color button" onClick={() => onClickDoneCreate()}>
                  Done
                </Button>
              </Stack>
            </Col>
          </Row>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Row>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} className="pb-3">
              <Stack gap={3}>
                <>
                  <Row>
                    <Col className="col-12">
                      <Select
                        label="Household Member Type"
                        value={type}
                        options={types}
                        onUpdate={(e: any) => updateType(e)}
                      />
                    </Col>
                  </Row>
                  {(type === "Guardian" || type === "Adult" || type === "Child") && (
                    <>
                      <Row>
                        <Col className="col-6">
                          <Input
                            label="First Name"
                            type="text"
                            value={firstName}
                            onUpdate={(e: any) => updateFirstName(e)}
                          />
                        </Col>
                        <Col className="col-6">
                          <Input
                            label="Last Name"
                            type="text"
                            value={lastName}
                            onUpdate={(e: any) => updateLastName(e)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {type === "Guardian" && (
                          <Col className="col-6">
                            <Input
                              label="Email"
                              type="text"
                              value={email}
                              onUpdate={(e: any) => updateEmail(e)}
                              required={true}
                            />
                          </Col>
                        )}
                        <Col className="col-6">
                          <InputPhone
                            label="Phone"
                            value={phone}
                            onUpdate={(e: any) => updatePhone(e)}
                            required={type === "Guardian" ? true : false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-6">
                          <Input
                            label="Preferred Name"
                            type="text"
                            value={preferredName}
                            onUpdate={(e: any) => updatePreferredName(e)}
                          />
                        </Col>
                        <Col className="col-6">
                          <DatePicker
                            label="Birth Date"
                            currentValue={birthDate}
                            setCurrentValue={updateBirthDate}
                            hasMaxDate={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-6">
                          <SelectObject
                            label="Relationship"
                            value={relationship}
                            options={type !== "Guardian" ? allRelationships : relationships}
                            onUpdate={(e: any) => updateRelationship(e)}
                          />
                        </Col>
                        <Col className="col-6">
                          <SelectObject
                            label="Gender"
                            value={gender}
                            options={genders}
                            onUpdate={(e: any) => updateGender(e)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-6">
                          <SelectObject
                            label="Ethnicity"
                            value={ethnicity}
                            options={ethnicities}
                            onUpdate={(e: any) => updateEthnicity(e)}
                          />
                        </Col>
                        <Col className="col-6">
                          <SelectObject
                            label="Race"
                            value={race}
                            options={races}
                            onUpdate={(e: any) => updateRace(e)}
                          />
                        </Col>
                      </Row>
                      {type === "Child" && (
                        <Row>
                          <Col className="col-6">
                            <Input label="School" type="text" value={school} onUpdate={(e: any) => updateSchool(e)} />
                          </Col>
                          <Col className="col-4">
                            <Input label="Grade" type="text" value={grade} onUpdate={(e: any) => updateGrade(e)} />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </>
              </Stack>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={6}>
              {isSaving ? (
                <Container>
                  <Button size="sm" className="spinner-button spinner-button-main mb-5">
                    <Spinner animation="border" style={{ color: "#F6893D" }} />
                  </Button>
                </Container>
              ) : (
                <Row className="flex-wrap">
                  {guardians.map((u: User) => (
                    <Col key={u.id} className="col-6 pb-3 d-flex">
                      <HouseholdCondensedCard user={u} />
                    </Col>
                  ))}
                  {household.map((u: User) => (
                    <Col key={u.id} className="col-6 pb-3 h-100">
                      <HouseholdCondensedCard user={u} />
                    </Col>
                  ))}
                  {children.map((u: User) => (
                    <Col key={u.id} className="col-6 pb-3 h-100 d-flex">
                      <HouseholdCondensedCard user={u} />
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
          <Row className="pt-0 pb-5">
            <Stack className="stack-float" direction="horizontal" gap={3}>
              <Button
                className="app-secondary-color button"
                onClick={() => saveMember(false)}
                disabled={!saveIsEnabled}
              >
                Save and Add Another
              </Button>
              <div className="vr" />
              <Button className="app-secondary-color button" onClick={() => saveMember(true)} disabled={!saveIsEnabled}>
                Save and Done
              </Button>
            </Stack>
          </Row>
        </Stack>
      </div>
    </>
  );
};

export default CreateMember;

import React from "react";
import { Form } from "react-bootstrap";

interface FormCheckState {
  label: string;
  value: any;
  onUpdate: any;
  disabled?: any;
}

const FormCheck = ({ label, value, onUpdate, disabled = false }: FormCheckState) => {
  return (
    <Form.Group key={`default-checkbox`} className="mb-1">
      <label style={{ display: "flex" }}>
        <Form.Check
          onChange={onUpdate}
          type="checkbox"
          id={`default-checkbox`}
          checked={value}
          key={label}
          disabled={disabled}
          className="me-2"
        />
        {label}
      </label>
    </Form.Group>
  );
};

export default FormCheck;

import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row, Toast } from "react-bootstrap";

interface ToastButtonState {
  showToast: any;
  setShowToast: any;
}

const ToastMsgButton = ({ showToast, setShowToast }: ToastButtonState) => {
  const onClickRefresh = () => {
    setShowToast(false);
    window.location.reload();
  };

  return (
    <Toast show={showToast} className="toast-location-bottom">
      <Toast.Body onClick={onClickRefresh} className="cursor-pointer">
        <Row>
          <Col sm={10}>
            <strong className="app-tertiary-color-inverse">{"Update Available - Click to Refresh "}</strong>
          </Col>
          <Col className="text-end">
            <FontAwesomeIcon
              icon={faRefresh}
              size="lg"
              onClick={onClickRefresh}
              className="app-tertiary-color-inverse"
            />
          </Col>
        </Row>
      </Toast.Body>
    </Toast>
  );
};

export default ToastMsgButton;

import { faDownload, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteDocument, fetchDocumentDownload, fetchDocumentView } from "../../../services/httpClient";

interface ViewFilesRowState {
  item: any;
  fetchDocuments: any;
  myCase: boolean;
  setIsLoading: any;
}

export const ViewFilesRow = ({ item, fetchDocuments, myCase, setIsLoading }: ViewFilesRowState) => {
  const { id } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [docToDelete, setDocToDelete] = useState<string>();
  const [canView, setCanView] = useState(true);

  useEffect(() => {
    const extensions = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "pdf", "PDF"];

    if (extensions.some((extension) => item.name.split(".").pop() === extension)) {
      setCanView(true);
    } else {
      setCanView(false);
    }
  }, [item.name]);

  const onClickDownload = (docId: string, docName: string) => {
    setIsDownloading(true);
    fetchDocumentDownload(id, docId)
      .then((res: any) => {
        FileSaver.saveAs(res[0].data, docName);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const onClickView = (docId: string, docName: string) => {
    fetchDocumentView(id, docId).then((res: any) => {
      const blobUrl = URL.createObjectURL(res[0].data);
      window.open(blobUrl, "_blank");
    });
  };

  const onClickDelete = (docId: string) => {
    setIsDeleting(true);
    setDocToDelete(docId);
  };

  const onClickDeleteFinal = async () => {
    setIsLoading(true);

    deleteDocument(id, docToDelete).then((res: any) => {
      fetchDocuments();
      setIsDeleting(false);
    });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setDocToDelete(undefined);
  };

  const renderDelete = () => {
    return (
      <tr>
        <td colSpan={2}>Are you sure you want to delete?</td>
        <td className="text-end w-50">
          <Button className="me-3 app-danger-color button" onClick={() => onClickDeleteFinal()}>
            Confirm Delete
          </Button>
          <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
            Cancel
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <>
      {isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <>
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.created_at}</td>
            <td className="text-end">
              {canView && (
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>View</Tooltip>}>
                  <Button className="button me-3 app-primary-color" onClick={() => onClickView(item.id, item.name)}>
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </OverlayTrigger>
              )}
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Download</Tooltip>}>
                <Button className="button me-3 app-tertiary-color" onClick={() => onClickDownload(item.id, item.name)}>
                  {isDownloading ? (
                    <Spinner className="spinner-small" animation="border" style={{ color: "#FFFFFF" }} />
                  ) : (
                    <FontAwesomeIcon icon={faDownload} />
                  )}
                </Button>
              </OverlayTrigger>
              {myCase && (
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Delete</Tooltip>}>
                  <Button className="button app-danger-color" onClick={() => onClickDelete(item.id)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </OverlayTrigger>
              )}
            </td>
          </tr>
        </>
      )}
    </>
  );
};

import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

interface SelectObjectState {
  initialOption?: string;
  label: string;
  value: any;
  options: any;
  onUpdate: any;
  disabled?: boolean;
  skipInitialOption?: boolean;
  cssClasses?: string;
}

const SelectObject = ({
  initialOption = "Select from List",
  label,
  value,
  options,
  onUpdate,
  disabled = false,
  skipInitialOption = false,
  cssClasses = "",
}: SelectObjectState) => {
  return (
    <FloatingLabel controlId="floatingInput" label={label} className={cssClasses}>
      <Form.Select
        className={cssClasses + " decorated"}
        value={value ? value : ""}
        onChange={onUpdate}
        disabled={disabled}
      >
        {skipInitialOption === false && (
          <option key={0} value={0}>
            {initialOption}
          </option>
        )}
        {options.map((i: any, index: any) => (
          <option key={index} value={i.key} className={i.isDeleted ? "text-muted" : ""}>
            {i.name}
            {i.isDeleted ? "->deleted" : ""}
          </option>
        ))}
      </Form.Select>
    </FloatingLabel>
  );
};
export default SelectObject;

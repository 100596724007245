import React, { useState } from "react";
import { postDocument } from "../../../services/httpClient";
import { useParams } from "react-router-dom";
import FileUploadUI from "../../ui/FileUploadUI";
import { Button, Container, Spinner } from "react-bootstrap";

interface FileUploadDocumentsState {
  header: string;
  fetchDocuments: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  setUserToSave: any;
}

const FileUploadDocuments = ({
  header,
  fetchDocuments,
  setShowToast,
  setToastText,
  setToastBody,
  setUserToSave,
}: FileUploadDocumentsState) => {
  const { id } = useParams();

  const [selectedFile, setSelectedFile]: any = useState();
  const [uploadIsEnabled, setUploadIsEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  async function uploadFileData(data: any) {
    setUserToSave(true);
    setIsLoading(true);

    postDocument(selectedFile.file.name, data, id)
      .then((res: any) => {
        setSelectedFile("");
        setUploadIsEnabled(false);
        fetchDocuments();
        setShowToast(true);
        setToastText("Document Created");
        setToastBody(selectedFile.file.name);
        uploadTimeout();
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        setUserToSave(false);
      })
      .finally(() => {
        setUserToSave(false);
      });
  }

  const uploadTimeout = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <FileUploadUI
          header={header}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          uploadIsEnabled={uploadIsEnabled}
          setUploadIsEnabled={setUploadIsEnabled}
          uploadFileData={uploadFileData}
        />
      )}
    </>
  );
};

export default FileUploadDocuments;

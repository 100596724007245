import React from "react";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { User } from "../../types/user";

interface GuardianTabState {
  users: User[];
}

const GuardianTab = ({ users }: GuardianTabState) => {
  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  }

  return (
    <>
      {/* {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : ( */}
      <Row className="mb-5">
        <Col>
          <Stack gap={3}>
            {users.map((user: any) => (
              <Card key={user.id} className="bg-light">
                <Card.Body>
                  <Stack direction="horizontal" gap={3}>
                    <Stack>
                      <Card.Subtitle>
                        {user.firstname} {user.preferredname ? "'" + user.preferredname + "'" : ""} {user.lastname}
                      </Card.Subtitle>
                      <Card.Subtitle className="text-muted mt-1">
                        {user.email +
                          (user.email && user.phonenumber ? " | " : "") +
                          formatPhoneNumber(user.phonenumber)}{" "}
                      </Card.Subtitle>
                    </Stack>
                    <Card.Subtitle className="text-muted mt-1">{user.casename} </Card.Subtitle>
                  </Stack>
                </Card.Body>
              </Card>
            ))}
          </Stack>
        </Col>
      </Row>
      {/* )} */}
    </>
  );
};

export default GuardianTab;

import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faClose,
  faFileText,
  faPlay,
  faStop,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip, ButtonGroup, Row, Col, Spinner } from "react-bootstrap";
import FormattedTranscript from "./FormattedTranscript";
import {
  deleteNoteRecordingsTranscript,
  fetchNoteRecordingData,
  uploadNoteRecordings,
} from "../../../services/httpClient";
import { trackFeatureEvent } from "../../../utils/analytics";
import { Recording } from "../../../types/recording";

interface RecordingItemState {
  caseId: string | undefined;
  note: any;
  recording: Recording;
  loadRecordings: any;
  myCase?: boolean;
}

const RecordingItem = ({ caseId, note, recording, loadRecordings, myCase }: RecordingItemState) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioRef, setAudioRef] = useState<HTMLAudioElement>();
  const [transcript, setTranscript] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [resubmit, setResubmit] = useState<boolean>(false);
  const [showTranscript, setShowTranscript] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isResubmitting, setIsResubmitting] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isRecordingPlayable, setIsRecordingPlayable] = useState(false);
  const [recordingData, setRecordingData] = useState();

  var currentAudioRef = audioRef;

  useEffect(() => {
    setSummary(recording?.transcript_summary);
    setTranscript(recording?.transcript_text);

    checkIfShouldResubmit(recording);

    const interval = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 1500);

    fetchNoteRecordingData(caseId, note?.id, recording?.id)
      .then((res: any) => {
        const rec = res[0].data;
        setRecordingData(rec?.data);
        setIsRecordingPlayable(true);
      })
      .catch((err: any) => {});

    return () => clearInterval(interval);
  }, []);

  const checkIfShouldResubmit = (recording: any) => {
    console.log(recording);
    const dateToCheck = new Date(recording?.transcript_created_at);
    const now = new Date();
    const differenceInMs = now.getTime() - dateToCheck.getTime();

    // Convert milliseconds to days
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    // Check if the difference is more than one day
    if (differenceInDays > 1 && recording?.transcript_text === null) {
      setResubmit(true);
    }
  };

  const resubmitRecording = () => {
    setIsPlaying(false);
    currentAudioRef?.pause();
    setIsResubmitting(true);

    deleteNoteRecordingsTranscript(caseId, note?.id, recording?.id)
      .then((res: any) => {
        const rec = res[0].data;
        console.log(rec);

        uploadNoteRecordings(caseId, note?.id, recording?.id)
          .then((res: any) => {
            const rec = res[0].data;
            console.log(rec);
            loadRecordings();
            setResubmit(false);
            setIsResubmitting(false);
          })
          .catch((err: any) => {
            console.log(err);
            setIsResubmitting(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setIsResubmitting(false);
      });

    trackFeatureEvent("button_click", "resubmit_recording");
  };

  const playRecording = () => {
    setIsPlaying(true);
    const base64Data = recordingData;
    currentAudioRef = new Audio(`data:audio/aac;base64,${base64Data}`);
    setAudioRef(currentAudioRef);
    currentAudioRef.oncanplaythrough = () => currentAudioRef?.play();
    currentAudioRef.load();

    currentAudioRef.onended = function () {
      setIsPlaying(false);
    };

    trackFeatureEvent("button_click", "play_recording");
  };

  const pauseRecording = () => {
    setIsPlaying(false);
    currentAudioRef?.pause();
  };

  const viewTranscript = () => {
    setShowTranscript(true);
    setShowSummary(false);

    trackFeatureEvent("button_click", "view_transcript");
  };

  const closeTranscript = () => {
    setShowTranscript(false);
    setShowSummary(true);
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          {resubmit ? (
            <>
              {isPlaying ? (
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Stop</Tooltip>}>
                  <Button className="me-3 app-primary-color button" onClick={() => pauseRecording()}>
                    <FontAwesomeIcon icon={faStop} />
                  </Button>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Play</Tooltip>}>
                  <Button
                    className="me-3 app-tertiary-color button"
                    onClick={() => playRecording()}
                    disabled={isRecordingPlayable ? false : true}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                  </Button>
                </OverlayTrigger>
              )}
              {myCase &&
                (isResubmitting ? (
                  <Button className="me-3 app-secondary-color button">
                    <Spinner size="sm" style={{ color: "#FFFFFF" }} className="me-2" />
                    Resubmit Recording for Transcription
                  </Button>
                ) : (
                  <Button className="me-3 app-secondary-color button" onClick={() => resubmitRecording()}>
                    <FontAwesomeIcon icon={faFileText} className="me-2" />
                    Resubmit Recording for Transcription
                  </Button>
                ))}
            </>
          ) : (
            <>
              <Card>
                <Card.Body>
                  {showSummary &&
                    (recording?.transcript_summary ? (
                      summary
                    ) : (
                      <Row className="text-center">
                        <Col>
                          <h6
                            className={
                              isVisible ? "app-orange-text fade-in-out" : " app-orange-text fade-in-out hidden"
                            }
                          >
                            Creating Summary <FontAwesomeIcon icon={faWandMagicSparkles} />
                          </h6>
                        </Col>
                      </Row>
                    ))}
                  {showTranscript &&
                    (recording?.transcript_text ? (
                      <>
                        <FormattedTranscript transcript={transcript} />
                      </>
                    ) : (
                      <Row className="text-center">
                        <Col>
                          <h6
                            className={
                              isVisible ? "app-orange-text fade-in-out" : " app-orange-text fade-in-out hidden"
                            }
                          >
                            Processing Transcript <FontAwesomeIcon icon={faFileText} />
                          </h6>
                        </Col>
                      </Row>
                    ))}
                </Card.Body>
                <Card.Footer className="text-end border-0 bg-white">
                  <ButtonGroup className="justify-content-end">
                    {menuOpen &&
                      (showTranscript ? (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Close</Tooltip>}
                        >
                          <Button className="me-3 app-primary-color button" onClick={() => closeTranscript()}>
                            <FontAwesomeIcon icon={faClose} />
                          </Button>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Transcript</Tooltip>}
                        >
                          <Button className="me-3 app-secondary-color button" onClick={() => viewTranscript()}>
                            <FontAwesomeIcon icon={faFileText} />
                          </Button>
                        </OverlayTrigger>
                      ))}

                    {menuOpen &&
                      (isPlaying ? (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Stop</Tooltip>}
                        >
                          <Button className="me-3 app-primary-color button" onClick={() => pauseRecording()}>
                            <FontAwesomeIcon icon={faStop} />
                          </Button>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Play</Tooltip>}
                        >
                          <Button
                            className="me-3 app-tertiary-color button"
                            onClick={() => playRecording()}
                            disabled={isRecordingPlayable ? false : true}
                          >
                            <FontAwesomeIcon icon={faPlay} />
                          </Button>
                        </OverlayTrigger>
                      ))}

                    {menuOpen ? (
                      <Button className="app-primary-color button" onClick={() => setMenuOpen(false)}>
                        <FontAwesomeIcon icon={faCircleChevronRight} />
                      </Button>
                    ) : (
                      <Button className="app-primary-color button" onClick={() => setMenuOpen(true)}>
                        <FontAwesomeIcon icon={faCircleChevronLeft} />
                      </Button>
                    )}
                  </ButtonGroup>
                </Card.Footer>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RecordingItem;

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

interface PageTitleState {
  title: string | undefined;
  buttonTitle?: string;
  onButtonClick?: any;
  buttonDisabled?: boolean;
  buttonColor?: string;
  type?: string;
  descriptionText?: string;
}

const PageTitle = ({
  title,
  buttonTitle,
  onButtonClick,
  buttonDisabled = false,
  buttonColor = "primary",
  type = "page",
  descriptionText,
}: PageTitleState) => {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  return (
    <>
      <Row className="pe-0">
        <Col className={onButtonClick ? "col-8" : "col-12"}>
          {type === "page" ? (
            <>
              <h2 className="hero-heading app-header-text">
                {title}
                {descriptionText && (
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="opacity-75 ms-2 mb-1 app-primary-color-inverse cursor-pointer"
                    fontSize={16}
                    onClick={() => setShowDescription(!showDescription)}
                  />
                )}
              </h2>
            </>
          ) : (
            <h4 className="hero-heading app-header-text">{title}</h4>
          )}
        </Col>
        {onButtonClick && (
          <Col className="text-end col-4 pe-0">
            <Button
              id="pagetitle-button"
              className={
                buttonColor === "primary"
                  ? "app-primary-color button"
                  : buttonColor === "secondary"
                  ? "app-secondary-color button"
                  : "btn-secondary button"
              }
              onClick={onButtonClick}
              disabled={buttonDisabled}
            >
              {buttonTitle}
            </Button>
          </Col>
        )}
      </Row>
      {showDescription && (
        <Row>
          <pre className="text-muted m-0">{descriptionText}</pre>
        </Row>
      )}
    </>
  );
};

export default PageTitle;

import { faEdit, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Note } from "../../../types/note";

interface ContactViewState {
  n: Note;
  setEdit: any;
  setCurrent: any;
}

const ContactView = ({ n, setEdit, setCurrent }: ContactViewState) => {
  const onClickEdit = () => {
    setEdit(true);
    setCurrent(n);
  };

  return (
    <Card className="bg-light mb-3 cursor-pointer" onClick={(e) => onClickEdit()}>
      <Card.Body>
        <>
          <Row className="mb-0">
            <Col>
              <h5 className="app-orange-text">Contact</h5>
            </Col>
            <Col className="text-end">
              {(n.fields.visit_hour || n.fields.visit_min) && (
                <label className="text-muted me-3">
                  {n.fields.visit_hour ? n.fields.visit_hour : "0"} hour(s){" "}
                  {n.fields.visit_min ? n.fields.visit_min : "0"} minute(s)
                </label>
              )}
              {n.fields.date && <label className="note-type text-muted me-3">{n.fields.date}</label>}
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Edit Note</Tooltip>}>
                <FontAwesomeIcon className="text-muted cursor-pointer" icon={faEdit} onClick={(e) => onClickEdit()} />
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Form.Control
              as="textarea"
              type="textarea"
              value={n.fields.summary_info}
              style={{
                height: "auto",
                pointerEvents: "none",
                backgroundColor: "#e9ecef",
                color: "#6c757d",
              }}
              className="cursor-pointer"
            />
          </Row>
          <Row className="mt-1">
            <Col className="col-6 text-start">
              <Badge bg="secondary" className="me-3">
                Created By {n.firstname} {n.lastname}
              </Badge>
              {n?.recordingsExist === true && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Recordings Exist</Tooltip>}
                >
                  <FontAwesomeIcon className="text-muted" icon={faMicrophone} />
                </OverlayTrigger>
              )}
            </Col>
            <Col className="col-6 text-end">
              <Badge bg="secondary">{n.subject?.name}</Badge>
            </Col>
          </Row>
        </>
      </Card.Body>
    </Card>
  );
};

export default ContactView;

import React, { useEffect, useState } from "react";
import { User } from "../../../types/user";
import { fetchUserCase, fetchUserCaseGuardians, fetchUserCaseMembers } from "../../../services/httpClient";
import { useParams } from "react-router-dom";
import ToastMsg from "../../ui/ToastMsg";
import PageTitle from "../../ui/PageTitle";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import HouseholdCard from "./HouseholdCard";
import EditGuardian from "./EditGuardian";
import EditMember from "./EditMember";
import SaveChangesModal from "../../ui/SaveChangesModal";
import CreateMember from "./CreateMember";

interface HouseholdUsersState {
  myCase: boolean;
  userToSave: boolean;
  setUserToSave: any;
  showModal: boolean;
  setShowModal: any;
  getData: boolean;
}

const HouseholdUsers = ({
  myCase,
  userToSave,
  setUserToSave,
  showModal,
  setShowModal,
  getData,
}: HouseholdUsersState) => {
  const { id } = useParams();
  const [guardians, setGuardians] = useState<User[]>([]);
  const [children, setChildren] = useState<User[]>([]);
  const [household, setHousehold] = useState<User[]>([]);
  const [primaryContact, setPrimaryContact] = useState<User>();
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentCase, setCurrentCase] = useState<any>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [create, setCreate] = useState<boolean>(false);
  const [editGuardian, setEditGuardian] = useState<boolean>(false);
  const [editChild, setEditChild] = useState<boolean>(false);
  const [editHousehold, setEditHousehold] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getCase();
      getHousehold();
    }
  }, [getData]);

  const getCase = () => {
    fetchUserCase(id)
      .then((res: any) => {
        setCurrentCase(res[0].data);
        console.log(res[0].data);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getHousehold = () => {
    fetchUserCaseGuardians(id)
      .then((res: any) => {
        const g = res[0].data;
        setGuardians(g);

        const primary = g.filter((u: User) => {
          if (u.is_primary) return u;

          return null;
        });

        setPrimaryContact(primary[0]);
        fetchUserCaseMembers(id)
          .then((res: any) => {
            const m = res[0].data;
            const c = m.filter((u: any) => u.relationship === "child");
            const h = m.filter((u: any) => u.is_guardian !== 1 && u.relationship !== "child");
            setChildren(c);
            setHousehold(h);

            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onLeave = () => {
    resetHouseholdTab();
  };

  const onClickDoneEdit = () => {
    if (userToSave) {
      setShowModal(true);
    } else {
      resetHouseholdTab();
    }
  };

  const showCreate = (e: any) => {
    if (userToSave) {
      setShowModal(true);
    } else {
      setCreate(true);
      setEditGuardian(false);
      setEditChild(false);
      setEditHousehold(false);
      setCurrentUser(undefined);
    }
  };

  const resetHouseholdTab = () => {
    setCreate(false);
    setEditGuardian(false);
    setEditChild(false);
    setEditHousehold(false);
    setCurrentUser(undefined);
    setUserToSave(false);
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          {create ? (
            <>
              <CreateMember
                fetchHousehold={getHousehold}
                startingGuardians={guardians}
                startingHousehold={household}
                startingChildren={children}
                onClickDoneEdit={onClickDoneEdit}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
              />
            </>
          ) : (
            <>
              {myCase ? (
                <PageTitle
                  title="Household"
                  buttonTitle="Add Member"
                  onButtonClick={() => showCreate(true)}
                  type="section"
                />
              ) : (
                <PageTitle title="Household" type="section" />
              )}
              <Row className="flex-wrap pb-3">
                <Col xs={8} sm={12}>
                  <Card className="w-100">
                    <Card.Body>
                      <Row>
                        <Col xs={8} sm={6} md={6} lg={3} xl={3}>
                          <Row className="mb-1">
                            <div className="px-3 py-2">
                              <h5 className="app-header-text">Address</h5>
                              {currentCase?.address_line_one ? (
                                <p className="pt-0 pb-0 mb-0">
                                  <small>
                                    {currentCase?.address_line_one && currentCase?.address_line_one}
                                    {currentCase?.address_line_one && <br />}
                                    {currentCase?.address_line_two && currentCase?.address_line_two}
                                    {currentCase?.address_line_two && <br />}
                                    {currentCase?.city &&
                                      currentCase?.state &&
                                      currentCase?.city + ", " + currentCase?.state + "  " + currentCase?.zip}
                                  </small>
                                </p>
                              ) : (
                                <small>No Address Saved</small>
                              )}
                            </div>
                          </Row>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={3} xl={3}>
                          <Row className="mb-1">
                            <div className="px-3 py-2">
                              <h5 className="app-header-text">Primary Contact</h5>
                              {primaryContact?.firstname ? (
                                <>
                                  <p className="pt-0 pb-0 mb-0">
                                    <small>
                                      {primaryContact?.firstname}{" "}
                                      {primaryContact?.preferredname ? "'" + primaryContact?.preferredname + "'" : ""}{" "}
                                      {primaryContact?.lastname}
                                    </small>
                                  </p>
                                  <p className="pt-0 pb-0 mb-0">
                                    <small>{primaryContact?.email}</small>
                                  </p>
                                  <p className="pt-0 pb-0 mb-0">
                                    <small>
                                      {primaryContact?.phonenumber === "1"
                                        ? ""
                                        : primaryContact?.phonenumber
                                        ? primaryContact?.phonenumber.replace(
                                            /(\d{1})(\d{3})(\d{3})(\d{3})/,
                                            "+$1 ($2) $3-$4"
                                          )
                                        : ""}
                                    </small>
                                  </p>
                                </>
                              ) : (
                                <small>No Primary Setup</small>
                              )}
                            </div>
                          </Row>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={3} xl={3}>
                          <Row className="mb-1">
                            <div className="px-3 py-2">
                              <h5 className="app-header-text">Support</h5>
                              {currentCase?.support?.length > 0 ? (
                                currentCase?.support?.map((c: any) => (
                                  <p className="pt-0 pb-0 mb-0">
                                    <small className="text-capitalize">{c?.user_name}</small>
                                  </p>
                                ))
                              ) : (
                                <small>No Support Member(s)</small>
                              )}
                            </div>
                          </Row>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={3} xl={3}>
                          <Row className="mb-1">
                            <div className="px-3 py-2">
                              <h5 className="app-header-text">Case Manager</h5>
                              {currentCase?.primaryCM ? (
                                <p className="pt-0 pb-0 mb-0">
                                  <small className="text-capitalize">
                                    {currentCase?.primaryCM?.user_name} - Primary
                                  </small>
                                </p>
                              ) : (
                                <small>No Primary Case Manager</small>
                              )}
                              {currentCase?.secondaryCM ? (
                                <p className="pt-0 pb-0 mb-0">
                                  <small className="text-capitalize">
                                    {currentCase?.secondaryCM?.user_name} - Secondary
                                  </small>
                                </p>
                              ) : (
                                <small>No Secondary Case Manager</small>
                              )}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {myCase ? <PageTitle title="Adults" type="section" /> : <PageTitle title="Adult(s)" type="section" />}
              <Row className="d-flex align-items-stretch flex-wrap">
                {guardians.map((u: User) => (
                  <Col xs={8} sm={8} md={6} lg={6} xl={4} key={u.id} className="pb-3 h-100">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreate={setCreate}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
                {household.map((u: User) => (
                  <Col xs={8} sm={8} md={6} lg={6} xl={4} key={u.id} className="pb-3 h-100">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreate={setCreate}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editGuardian && (
                <EditGuardian
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  setUserToSave={setUserToSave}
                  guardians={guardians}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
              {editHousehold && (
                <EditMember
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Household Member"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
              <PageTitle title="Children" type="section" />
              <Row className="flex-wrap align-items-start align-items-stretch">
                {children.map((u: User) => (
                  <Col xs={8} sm={8} md={6} lg={6} xl={4} key={u.id} className="pb-3 h-100">
                    <HouseholdCard
                      user={u}
                      setEditGuardian={setEditGuardian}
                      setEditChild={setEditChild}
                      setEditHousehold={setEditHousehold}
                      setCreate={setCreate}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      userToSave={userToSave}
                      setShowModal={setShowModal}
                    />
                  </Col>
                ))}
              </Row>
              {editChild && (
                <EditMember
                  user={currentUser}
                  fetchHousehold={getHousehold}
                  resetHouseholdTab={resetHouseholdTab}
                  type="Child"
                  setUserToSave={setUserToSave}
                  onClickDoneEdit={onClickDoneEdit}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  myCase={myCase}
                />
              )}
            </>
          )}
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} onLeave={() => onLeave()} />
        </>
      )}
    </>
  );
};

export default HouseholdUsers;

import React, { useEffect, useState } from "react";
import { Row, Col, Container, Stack, Button, Spinner, Form } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { fetchAnnouncement, patchAnnouncement, postAnnouncement } from "../../services/httpClient";
import DatePicker from "../ui/DatePicker";
import Input from "../ui/Input";
import PageTitle from "../ui/PageTitle";
import ToastMsg from "../ui/ToastMsg";
import Textarea from "../ui/Textarea";
import SaveChangesModal from "../ui/SaveChangesModal";
import { unsavedChanges, currentUrl } from "../../App";
import useApp from "../../utils/useApp";
import InputValidUrl from "../ui/InputValidUrl";

const EditAnnouncement = () => {
  const { showMenu }: any = useApp();
  const location = useLocation();
  currentUrl.value = location.pathname;
  const { id } = useParams();
  const [editMode, setEditMode] = useState(id !== undefined ? true : false);
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const [eventDate, setEventDate] = useState<string>("");
  const [eventUrl, setEventUrl] = useState<string>("");
  const [isValidUrl, setIsValidUrl] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [publishIsEnabled, setPublishIsEnabled] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const heading = editMode ? "Edit Announcement" : "Create Announcement";
  const currentDate = new Date();
  const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  let navigate = useNavigate();

  const onClickCancel = (e: any) => {
    if (saveIsEnabled) {
      setShowModal(true);
    } else {
      navigate(`/announcements`);
    }
  };

  useEffect(() => {
    if (saveIsEnabled) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [saveIsEnabled]);

  useEffect(() => {
    if (editMode) {
      getAnnouncement();
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const enablePublish = () => {
    if (name && new Date(`${eventDate}T00:00:00`) >= currentDateOnly) {
      setPublishIsEnabled(true);
    } else {
      setPublishIsEnabled(false);
    }
  };

  const enableSave = () => {
    if (name) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  };

  const getAnnouncement = () => {
    fetchAnnouncement(id)
      .then((res: any) => {
        setName(res[0].data.name);
        setDescription(res[0].data.description);
        setEventDate(res[0].data.event_at);
        setEventUrl(res[0].data.event_url);

        if (res[0].data.name && new Date(`${res[0].data.event_at}T00:00:00`) >= currentDateOnly) {
          setPublishIsEnabled(true);
        } else {
          setPublishIsEnabled(false);
        }

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const saveAnnouncement = () => {
    setIsLoading(true);
    setErrMsg("");

    const updatedAnn = {
      name: name,
      description: description,
      event_at: eventDate ? eventDate : currentDate,
      event_url: eventUrl,
      is_published: 0,
    };

    if (editMode) {
      patchAnnouncement(updatedAnn, id)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setShowToast(true);
          setToastText("Announcement Draft Updated");
          const toast = name + "";
          setToastBody(toast);
        })
        .catch((err: any) => {
          setIsLoading(false);
          setErrMsg(err?.response?.data?.msg);
        })
        .finally(() => {
          unsavedChanges.value = false;
          setIsLoading(false);
        });
    } else {
      postAnnouncement(updatedAnn)
        .then((res: any) => {
          console.log(res[0]?.data);
          setSaveIsEnabled(false);
          setEditMode(true);
          setShowToast(true);
          setToastText("Announcement Draft Created");
          const toast = name + "";
          setToastBody(toast);
          navigate(`/announcements/announcement/${res[0].data?.announcement_id}`);
        })
        .catch((err: any) => {
          setIsLoading(false);
          setErrMsg(err?.response?.data?.msg);
        })
        .finally(() => {
          unsavedChanges.value = false;
          setIsLoading(false);
        });
    }
  };

  const publishAnnouncement = () => {
    setIsLoading(true);
    setErrMsg("");

    const updatedAnn = {
      name: name,
      description: description,
      event_at: eventDate ? eventDate : currentDate,
      event_url: eventUrl,
      is_published: 1,
    };

    if (editMode) {
      patchAnnouncement(updatedAnn, id)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setShowToast(true);
          setToastText("Announcement Published");
          const toast = name + "";
          setToastBody(toast);
        })
        .catch((err: any) => {
          setIsLoading(false);
          setErrMsg(err?.response?.data?.msg);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
            unsavedChanges.value = false;
            navigate(`/announcements`);
          }, 1500);
        });
    } else {
      postAnnouncement(updatedAnn)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setShowToast(true);
          setToastText("Announcement Published");
          const toast = name + "";
          setToastBody(toast);
        })
        .catch((err: any) => {
          setIsLoading(false);
          setErrMsg(err?.response?.data?.msg);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
            unsavedChanges.value = false;
            navigate(`/announcements`);
          }, 1500);
        });
    }
  };

  // const clearUserState = () => {
  //   setName("");
  //   setDescription("");
  //   setEventDate("");
  //   setEventUrl("");
  //   unsavedChanges.value = false;
  //   setSaveIsEnabled(false);
  //   setPublishIsEnabled(false);
  // };

  const onClickViewEvent = () => {
    window.open(eventUrl, "_blank");
  };

  const updateName = (e: any) => {
    setName(e.target.value);
    if (e.target.value) {
      setSaveIsEnabled(true);
      if (eventDate && new Date(`${eventDate}T00:00:00`) < currentDateOnly) {
        setPublishIsEnabled(false);
      } else {
        setPublishIsEnabled(true);
      }
    } else {
      setSaveIsEnabled(false);
      setPublishIsEnabled(false);
    }
  };

  const updateDescription = (e: any) => {
    setDescription(e.target.value);
    enableSave();
    enablePublish();
  };

  const updateEventDate = (date: any) => {
    setEventDate(date);
    enableSave();

    if (name && new Date(`${date}T00:00:00`) >= currentDateOnly) {
      setPublishIsEnabled(true);
    } else {
      setPublishIsEnabled(false);
    }
  };

  useEffect(() => {
    if (isValidUrl) {
      enableSave();
      enablePublish();
    } else {
      setSaveIsEnabled(false);
      setPublishIsEnabled(false);
    }
  }, [eventUrl]);

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Row className="mb-3">
            <Col className="col-6 text-start">
              <Button className={"app-primary-color button"} onClick={(e) => onClickCancel(e)}>
                {"Back to Announcements"}
              </Button>
            </Col>
            <Col className="col-6 text-end">
              <Stack direction="horizontal" gap={3} className="justify-content-end">
                <Button
                  id="pagetitle-button"
                  className={"app-secondary-color button"}
                  onClick={saveAnnouncement}
                  disabled={!saveIsEnabled}
                >
                  {"Save Draft"}
                </Button>
                <div className="vr mb-1" />
                <Button
                  id="pagetitle-button"
                  className={"app-tertiary-color button"}
                  onClick={publishAnnouncement}
                  disabled={!publishIsEnabled}
                >
                  {"Publish"}
                </Button>
              </Stack>
            </Col>
          </Row>
          <PageTitle title={heading} />
          <Stack gap={3}>
            <Row>
              <Col className="col-6">
                <Input label="Name" type="text" value={name} onUpdate={(e: any) => updateName(e)} required={true} />
              </Col>
            </Row>
            <Row>
              <Col className="col-8 p-0">
                <Textarea label="Description" value={description} onUpdate={(e: any) => updateDescription(e)} />
              </Col>
            </Row>
            <Row>
              <Col className="col-2">
                <DatePicker label="Event Date" currentValue={eventDate} setCurrentValue={updateEventDate} />
              </Col>
              <Col className="col-10">
                <Form.Group className="input-group mb-3">
                  <InputValidUrl
                    label="Event Url"
                    url={eventUrl}
                    setUrl={setEventUrl}
                    isValid={isValidUrl}
                    setIsValid={setIsValidUrl}
                  />
                  <Button
                    id="button-addon"
                    className="app-primary-color"
                    onClick={() => onClickViewEvent()}
                    disabled={eventUrl ? !isValidUrl : true}
                  >
                    View
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Stack>
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/announcements" />
        </Container>
      )}
    </>
  );
};

export default EditAnnouncement;

import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { FloatingLabel, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

interface InputState {
  label: string;
  type: string;
  value: any;
  onUpdate: any;
  disabled?: any;
  required?: boolean;
  hasFocus?: boolean;
}

const Input = ({ label, type, value, onUpdate, disabled = false, required = false, hasFocus = false }: InputState) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasFocus) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <>
      {required ? (
        <OverlayTrigger placement="top" delay={{ show: 150, hide: 250 }} overlay={<Tooltip>Required</Tooltip>}>
          <FloatingLabel controlId="floatingInput" label={label} className="sm px-0">
            <FontAwesomeIcon
              className="app-tertiary-color-inverse"
              icon={faAsterisk}
              style={{ position: "absolute", right: 5, top: 5, fontSize: 11 }}
            />
            <Form.Control
              type={type}
              placeholder={label}
              value={value ? value : ""}
              onChange={onUpdate}
              disabled={disabled}
              ref={inputRef}
            ></Form.Control>
          </FloatingLabel>
        </OverlayTrigger>
      ) : (
        <FloatingLabel controlId="floatingInput" label={label} className="sm px-0">
          <Form.Control
            type={type}
            placeholder={label}
            value={value ? value : ""}
            onChange={onUpdate}
            disabled={disabled}
            ref={inputRef}
          />
        </FloatingLabel>
      )}
    </>
  );
};

export default Input;

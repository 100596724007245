import axios from "axios";

const api = axios.create({
  withCredentials: true,
  baseURL: "/api",
  responseType: "json",
});

api.interceptors.response.use(
  (response) => {
    localStorage.setItem("expiration", new Date(Date.now() + 240 * 60 * 1000).toString());

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;

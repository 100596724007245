import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Textarea from "../../ui/Textarea";
import SelectFamily from "./SelectFamily";
import ViewRecordings from "./ViewRecordings";
import TimeDuration from "../../ui/TimeDuration";
import DatePicker from "../../ui/DatePicker";
import { User } from "../../../types/user";

interface ContactState {
  note?: any;
  family: User[];
  setUpdatedNote: any;
  setSaveIsEnabled: any;
  setUserToSave: any;
  myCase?: boolean;
  editNote?: boolean;
}

const Contact = ({
  note,
  family,
  setUpdatedNote,
  setSaveIsEnabled,
  setUserToSave,
  myCase,
  editNote = false,
}: ContactState) => {
  const mainRef = useRef();
  const [familyUser, setFamilyUser] = useState<string>("");
  const [visitHour, setVisitHour] = useState<number>(0);
  const [visitMin, setVisitMin] = useState<number>(0);
  const [summary, setSummary] = useState<string>("");
  const [noteDate, setNoteDate] = useState<string>("");

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    handleScroll(mainRef);

    if (editNote) {
      setFamilyUser(note?.subject?.id + "|" + note?.subject?.type);
      setNoteDate(note?.fields?.date);
      setVisitHour(note.fields.visit_hour);
      setVisitMin(note.fields.visit_min);
      setSummary(note.fields.summary_info);
    }
  }, []);

  useEffect(() => {
    const parts = familyUser.split("|");

    const note = {
      notetype: "contact",
      subject: {
        id: parts[0],
        type: parts[1],
      },
      fields: {
        date: noteDate,
        visit_hour: visitHour,
        visit_min: visitMin,
        summary_info: summary,
      },
    };

    setUpdatedNote(note);
  }, [familyUser, noteDate, visitHour, visitMin, summary, setUpdatedNote]);

  const updateFamilyUser = (e: any) => {
    setFamilyUser(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateSummary = (e: any) => {
    setSummary(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateDate = () => {
    setSaveIsEnabled(true);
    setUserToSave(true);
  };

  useEffect(() => {
    if (!noteDate) {
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  }, [noteDate]);

  return (
    <>
      <Row className="mt-4" ref={mainRef}>
        <SelectFamily
          family={family}
          familyUser={familyUser}
          updateFamilyUser={updateFamilyUser}
          readOnly={myCase ? false : true}
        />
        <Col className="col-lg-4 col-md-6 col-sm-8">
          <DatePicker
            label="Date"
            currentValue={noteDate}
            setCurrentValue={setNoteDate}
            onUpdate={() => updateDate()}
            required={true}
            hasMaxDate={true}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      {note?.recordingsExist === true && <ViewRecordings note={note} editNote={editNote} myCase={myCase} />}
      <TimeDuration
        hours={visitHour}
        setHours={setVisitHour}
        minutes={visitMin}
        setMinutes={setVisitMin}
        setSaveIsEnabled={setSaveIsEnabled}
        setUserToSave={setUserToSave}
        disabled={myCase ? false : true}
      />
      <Row className="mb-5">
        <Textarea
          label="Information Discussed"
          value={summary}
          onUpdate={(e: any) => updateSummary(e)}
          disabled={myCase ? false : true}
        />
      </Row>
    </>
  );
};

export default Contact;

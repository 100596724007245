import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { User } from "../../../types/user";

interface HouseholdCardState {
  user: User;
}

const HouseholdCondensedCard = ({ user }: HouseholdCardState) => {
  const [age, setAge] = useState<number | null>(15);

  useEffect(() => {
    // Check if birthdate is valid
    if (user?.birthdate && user?.birthdate !== "0000-00-00") {
      const calculateAge = (birthdate: string) => {
        const birthDateObj = new Date(birthdate);
        const today = new Date();

        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDiff = today.getMonth() - birthDateObj.getMonth();

        // Adjust age if the birthdate hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
          age--;
        }
        return age;
      };

      setAge(calculateAge(user.birthdate));
    } else {
      setAge(null); // Don't calculate age if birthdate is invalid
    }
  }, [user]);

  return (
    <Card className="bg-light card-household-condensed w-100">
      <Card.Body className="p-3">
        <Row className="me-0">
          <Col className="col-12">
            <h6 className="app-header-text pb-0 mb-0">
              {user.firstname} {user.preferredname ? "'" + user.preferredname + "'" : ""} {user.lastname}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            {user.relationship === "child" ? (
              <Row className="mb-1">
                <p className="text-muted first-letter mb-0">
                  {user?.grade && age
                    ? `Grade ${user?.grade} - Age ${age}`
                    : user?.grade
                    ? `Grade ${user?.grade}`
                    : age && `Age ${age}`}
                </p>
              </Row>
            ) : (
              <Row className="mb-1">
                <p className="text-muted first-letter mb-0">{user.relationship}</p>
              </Row>
            )}
          </Col>
        </Row>
        {user.is_new && (
          <div className="position-absolute bottom-0 end-0">
            <Badge bg="none" className="app-tertiary-color me-2 mb-2">
              New
            </Badge>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default HouseholdCondensedCard;
